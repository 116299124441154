<template>
<div class="modal" @click="emits('close')">
  <slot/>
</div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'

const props = defineProps({
  useHidden: Boolean,
})
const emits = defineEmits([ 'close' ])

function control(sw)
{
  if (!props.useHidden) return
  if (!document) return
  document.querySelector('html').classList[sw ? 'add' : 'remove']('mode-modal')
}

onMounted(() => control(true))
onUnmounted(() => control(false))
</script>

<style lang="scss" scoped>
@use '../../assets/scss/mixins';
.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: hsl(0 0% 100% / 0%);
  @include mixins.background-blur(8px);
  display: grid;
  place-content: center;
  z-index: 10;
  cursor: zoom-out;
}
</style>
